import React from 'react'

const Earth = (doc) => {
  doc.cases.sort((b, a) => a.uid.localeCompare(b.uid))
  return (
    <div id="columns">
      <div id="earth-column">
        <div id="myearth" className="earth-container"></div>
      </div>

      <div id="container-column">
        <hr></hr>
        <h1>Cases</h1>

        <div id="scroll_container">
          {doc.cases.map((caseItem, index) => {
            return (
              <a
                href={
                  caseItem.data.redirect_to_another_page
                    ? caseItem.data.redirect_link.url
                    : caseItem.url
                }
                key={`case-item=${index}`}
              >
                <div
                  className="section"
                  data-location={`${caseItem.data.earth_geo_point.latitude}, ${caseItem.data.earth_geo_point.longitude}`}
                  data-tooltip={caseItem.data.earth_title.text}
                  data-url={
                    caseItem.data.redirect_to_another_page
                      ? caseItem.data.redirect_link.url
                      : caseItem.url
                  }
                >
                  <h3>{caseItem.data.earth_subtitle.text}</h3>
                  <h2>{caseItem.data.earth_title.text}</h2>
                  <h4>{caseItem.data.earth_excerpt.text}</h4>

                  <button>Read more</button>
                </div>
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default Earth
