import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Header } from '../components/Header'
import { Seo } from '../components/Seo'

import Earth from '../components/Earth'
import '../styles/styles.css'

const Case = ({ data }) => {
  if (!data) return null
  const doc = data.allPrismicCase.nodes

  useEffect(() => {
    const scripts = [
      '../miniature.earth.js',
      '../twww-earth-conf.js',
      '../miniature.earth.scrollsync.js',
    ]

    for (var index = 0; index < scripts.length; ++index) {
      var script = document.createElement('script')
      script.src = scripts[index]
      script.type = 'text/javascript'
      var done = false
      script.onload = script.onreadystatechange = function () {
        if (
          !done &&
          (!this.readyState ||
            this.readyState == 'loaded' ||
            this.readyState == 'complete')
        ) {
          done = true
          //promptForUserEntries();
        }
      }
      document.getElementsByTagName('head')[0].appendChild(script)
    }
  }, [])

  return (
    <>
      <Header />
      <Seo title="Cases" description="The World We Want Foundation" />
      <Earth cases={doc} />
    </>
  )
}

export const query = graphql`
  query CaseLink {
    allPrismicCase {
      nodes {
        data {
          earth_excerpt {
            richText
            text
          }
          earth_geo_point {
            latitude
            longitude
          }
          earth_subtitle {
            text
          }
          earth_title {
            text
          }
          redirect_to_another_page
          redirect_link {
            url
          }
        }
        url
        uid
      }
    }
  }
`
export default Case
